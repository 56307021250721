import { Fragment, useEffect, useState } from "react";

import { Row, Col, Card, Table } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Another_Data, RegesterBy_Ad } from "../../../services/api_function";
import { NotificationManager } from "react-notifications";

const UnregesterData = () => {
    const [apidata, setApidata] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState("");
    const pageSize = 100;

    const fetchData = async () => {

        try {
            const userDetails = localStorage.getItem("userDetails");
            const parsedDetails = JSON.parse(userDetails);
            const token = parsedDetails.token;
            const data = await Another_Data(currentPage, token);
            setApidata(data?.data);
            const pages = Math.ceil(data.totalUser / pageSize);
            setTotalPages(pages > 0 ? pages : 1);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage < totalPages ? prevPage + 1 : prevPage
        );
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    };

    return (
        <Fragment>
            <Row>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        marginBottom: "20px",
                    }}
                >
                    {/* <div className="input-group" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              onChange={handleSearch}
            />
          </div> */}
                    <label class="form-label" for="form1"></label>
                </div>

                <Col lg={12}>
                    <Card>
                        <Card.Header
                            style={{ background: "black", border: "1px solid white" }}
                        >
                            <Card.Title style={{ color: "white", margin: "auto" }}>
                              
                            </Card.Title>
                        </Card.Header>
                        <Card.Body
                            style={{
                                background: "black",
                                border: "1px solid white",
                                borderRadius: "3px",
                            }}
                        >
                            <Table
                                responsive
                                style={{
                                    background: "black",
                                    color: "white",
                                    borderBottom: "0.5px solid white",
                                }}
                            >

                                <thead>
                                    <tr>
                                        <th>
                                            <strong>NO.</strong>
                                        </th>
                                        <th>
                                            <strong>Name</strong>
                                        </th>

                                        <th>
                                            <strong> UserID</strong>
                                        </th>
                                        <th>

                                            <strong>User wallet</strong>
                                        </th>

                                        <th>
                                            <strong> Referral Id</strong>
                                        </th>
                                        <th>

                                            <strong>Referral address</strong>
                                        </th>

                                        <th>
                                            <strong>Date&Time</strong>
                                        </th>
                                        <th>
                                            Register
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {apidata?.map((user, index) => (
                                        <tr>
                                            <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                            <td>{user?.name}</td>
                                            <td>{user?.userId}</td>
                                            <td>{user?.wallet_add}</td>
                                            <td>{user?.referrerId}</td>
                                            <td>{user?.reffrealaddresh}</td>
                                            <td>{formatTimestamp(user?.createdAt)}</td>
                                            <td>
                                                <button
                                                    className="btn btn-success"
                                                    onClick={async () => {
                                                        try {
                                                            // Call the registration function
                                                            const response = await RegesterBy_Ad(user?.wallet_add, user?.reffrealaddresh);

                                                            if (response.status == 200) {
                                                                NotificationManager.success('Registration successful!');
                                                            } else {
                                                                NotificationManager.error('Registration failed: ' + response.message);
                                                            }

                                                            fetchData();
                                                        } catch (error) {
                                                            console.error('Error during registration:', error);
                                                            alert('An error occurred while registering.');
                                                        }
                                                    }}
                                                >
                                                    Register
                                                </button>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <div className="d-flex justify-content-between">
                                <span>
                                    <strong></strong>
                                </span>
                            </div>
                            <div
                                className="text-center mb-3 col-lg-6"
                                style={{ margin: "auto" }}
                            >
                                <div className="filter-pagination  mt-3 bg-black">
                                    <button
                                        className="previous-button"
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1}
                                        style={{
                                            background:
                                                " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                                            color: "black",
                                        }}
                                    >
                                        {"<<"}
                                    </button>

                                    <button
                                        className="previous-button"
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1}
                                        style={{
                                            background:
                                                " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                                            color: "black",
                                        }}
                                    >
                                        Previous
                                    </button>

                                    <button
                                        className="next-button"
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                        style={{
                                            background:
                                                " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                                            color: "black",
                                        }}
                                    >
                                        Next
                                    </button>

                                    <button
                                        className="next-button"
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                        style={{
                                            background:
                                                " linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",
                                            color: "black",
                                        }}
                                    >
                                        {">>"}
                                    </button>

                                    <span className="bg-black text-white">
                                        Page {currentPage} of {totalPages}
                                    </span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default UnregesterData;
