import React, { Fragment, useState } from "react";
import { UserRegesation, idToaddress, validateReferral } from "../../../services/api_function";
import { toast } from "react-toastify";
import { isRegisteredInContract, registration, web3 } from "./web3/web3Helper";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Web3 from "web3";
import { gridColumnPositionsSelector } from "@mui/x-data-grid";
const UserRegester = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    address: "",
    referrerId: "",
    usertype: "",
    email: ""
  });

  const [signData, setsignData] = useState({
    name: "",
    phone: "",
    address: "",
    referrerId: "",
    usertype: "",
    email: ""
  });


  const [formDataForex, setFormDataForex] = useState({
    name: "",
    phone: "",
    address: "",
    referrerId: "",
    usertype: "",
    email: "",
    userId: "",
    refer_wallet:""
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const handleChangeSignUp = (e) => {
    const { name, value } = e.target;
    setsignData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeForez = (e) => {
    const { name, value } = e.target;
    setFormDataForex((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  console.log(signData, "signData")
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.phone || !formData.usertype || !formData.email) {
      NotificationManager.error("All Filed required fields");
      return;
    }
    try {
      const refaddr = await idToaddress(formData);
      console.log(refaddr?.data, "rrdddd");

      if (refaddr?.status == 200) {
        const checksumAddress = web3.utils.toChecksumAddress(formData.address);
        console.log("cheksumaddd", checksumAddress);

        setFormData((prevFormData) => ({
          ...prevFormData,
          address: checksumAddress,
        }));
      const  dataValidate = await validateReferral(formData.address, refaddr?.data?.data?.user);
      console.log(dataValidate, "dataValidate");
      
        const isreg = await isRegisteredInContract(formData.address);
        console.log(isreg, "is registered or not");

        if (!isreg) {
          console.log("let rock");
          await registration(formData.address, refaddr?.data?.data?.user,dataValidate.data);
          console.log("worked is reg");
        }

        if (isreg) {
          console.log("worked");
          const response = await UserRegesation(formData);
          if (response?.data?.status == 201) {
            NotificationManager.success(response?.data?.message);
            navigate("/block");

            console.log(response?.data?.message, response, "::::::::::::::::");
          } else {
            NotificationManager.error(response?.data?.message);
            // toast.error(response?.data?.message);
            console.log(
              response?.data?.data?.message,
              response,
              "::::::::::::::::"
            );
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validateEthereumAddress = (address) => {

    try {
      if (web3) {
        return web3.utils.toChecksumAddress(address);
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  const handleSubmitChnage = async (e) => {
    e.preventDefault();

    const url = `https://api.wyscale.net/newapi/signup`;
    const params = new URLSearchParams({
      uid:formDataForex.userId, 
      mno: formDataForex.phone,
      name: formDataForex.name,
      refid: formDataForex.referrerId,
      user_wallet: formDataForex.address,
      refer_wallet: formDataForex.refer_wallet,
    });

    try {
      const response = await fetch(`${url}?${params}`, {
        method: "GET",
      });

      const result = await response.json();
      NotificationManager.success("Data submitted successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to submit data.");
    }
  };

  const handleSubmitSigUp = async (e) => {
    e.preventDefault();

    if (!signData.name || !signData.phone || !signData.usertype || !signData.email) {
      NotificationManager.error("All Filed required fields");
      return;
    }
    try {
      
        
      const checksumAddress = validateEthereumAddress(signData.address);
      if (!checksumAddress) return;

      
      const updatedSignData = { ...signData, address: checksumAddress };
      setsignData(updatedSignData);

  
      const response = await UserRegesation(signData);
      if (response?.data?.status == 201) {
        NotificationManager.success(response?.data?.message);
        navigate("/block");

        console.log(response?.data?.message, response, "::::::::::::::::");
      } else {
        NotificationManager.error(response?.data?.message)
        console.log(
          response?.data?.data?.message,
          response,
          "::::::::::::::::"
        );
      }


    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Fragment>
      {/* <form autoComplete="off">
        <div class="mb-3 col-lg-4">
          <label>Name</label>
          <input
            type="text"
            name="name"
            className="form-control"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div class="mb-3 col-lg-4">
          <label>Phone</label>
          <input
            type="text"
            name="phone"
            className="form-control"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div class="mb-3 col-lg-4">
          <label>Email</label>
          <input
            type="text"
            name="email"
            className="form-control"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div class="mb-3 col-lg-4">
          <label for="exampleInputPassword1" class="form-label">
            Wallet
          </label>
          <input
            type="text"
            name="address"
            className="form-control"
            value={formData.address}
            onChange={handleChange}
          />
        </div>
        <div class="mb-3 col-lg-4">
          <label for="exampleInputPassword1" class="form-label">
            Referral
          </label>
          <input
            type="text"
            name="referrerId"
            className="form-control"
            value={formData.referrerId}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3 col-lg-4">
          <label htmlFor="userType" className="form-label">
            User Type
          </label>
          <select
            name="usertype"
            className="form-select"
            value={formData?.usertype || ""}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Choose User Type
            </option>
            <option value="consultant">AGENT</option>
            <option value="client">CLIENT</option>
          </select>
        </div>
        <button type="submit" class="btn btn-primary" onClick={handleSubmit}>
          Submit
        </button>
      </form>
      <form autoComplete="off">
        <div class="mb-3 col-lg-4">
          <label>Name</label>
          <input
            type="text"
            name="name"
            className="form-control"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div class="mb-3 col-lg-4">
          <label>Phone</label>
          <input
            type="text"
            name="phone"
            className="form-control"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div class="mb-3 col-lg-4">
          <label>Email</label>
          <input
            type="text"
            name="email"
            className="form-control"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div class="mb-3 col-lg-4">
          <label for="exampleInputPassword1" class="form-label">
            Wallet
          </label>
          <input
            type="text"
            name="address"
            className="form-control"
            value={formData.address}
            onChange={handleChange}
          />
        </div>
        <div class="mb-3 col-lg-4">
          <label for="exampleInputPassword1" class="form-label">
            Referral
          </label>
          <input
            type="text"
            name="referrerId"
            className="form-control"
            value={formData.referrerId}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3 col-lg-4">
          <label htmlFor="userType" className="form-label">
            User Type
          </label>
          <select
            name="usertype"
            className="form-select"
            value={formData?.usertype || ""}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Choose User Type
            </option>
            <option value="consultant">AGENT</option>
            <option value="client">CLIENT</option>
          </select>
        </div>
        <button type="submit" class="btn btn-primary" onClick={handleSubmit}>
          Submit
        </button>
      </form> */}

      <div className="container">
        <div className="row">
          {/* First Form */}

          <div className="col-md-6">
            <h4 className="text-center" style={{ color: "white" }}>Registration</h4>
            <form autoComplete="off">
              <div className="mb-3">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label>Phone</label>
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label>Email</label>
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label>Wallet</label>
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>Referral</label>
                <input
                  type="text"
                  name="referrerId"
                  className="form-control"
                  value={formData.referrerId}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label>User Type</label>
                <select
                  name="usertype"
                  className="form-select"
                  value={formData?.usertype || ""}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Choose User Type
                  </option>
                  <option value="consultant">AGENT</option>
                  <option value="client">CLIENT</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                Submit
              </button>
            </form>
          </div>

          {/* Second Form */}

          <div className="col-md-6">
            <h4 className="text-center" style={{ color: "white" }}>SignUP</h4>
            <form autoComplete="off">
              <div className="mb-3">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={signData.name}
                  onChange={handleChangeSignUp}
                  required
                />
              </div>
              <div className="mb-3">
                <label>Phone</label>
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  value={signData.phone}
                  onChange={handleChangeSignUp}
                  required
                />
              </div>
              <div className="mb-3">
                <label>Email</label>
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  value={signData.email}
                  onChange={handleChangeSignUp}
                  required
                />
              </div>
              <div className="mb-3">
                <label>Wallet</label>
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  value={signData.address}
                  onChange={handleChangeSignUp}
                />
              </div>
              <div className="mb-3">
                <label>Referral</label>
                <input
                  type="text"
                  name="referrerId"
                  className="form-control"
                  value={signData.referrerId}
                  onChange={handleChangeSignUp}
                />
              </div>
              <div className="mb-3">
                <label>User Type</label>
                <select
                  name="usertype"
                  className="form-select"
                  value={signData?.usertype || ""}
                  onChange={handleChangeSignUp}
                  required
                >
                  <option value="" disabled>
                    Choose User Type
                  </option>
                  <option value="consultant">AGENT</option>
                  <option value="client">CLIENT</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary" onClick={handleSubmitSigUp}>
                SignUp
              </button>
            </form>
          </div>
          <div className="col-md-6">
            <h4 className="text-center" style={{ color: "white" }}>Forex Send</h4>
            <form autoComplete="off">
            <div className="mb-3">
                <label>User ID</label>
                <input
                  type="text"
                  name="userId"
                  className="form-control"
                  value={formDataForex.userId}
                  onChange={handleChangeForez}
                  required
                />
              </div>
              <div className="mb-3">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={formDataForex.name}
                  onChange={handleChangeForez}
                  required
                />
              </div>
              <div className="mb-3">
                <label>Phone</label>
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  value={formDataForex.phone}
                  onChange={handleChangeForez}
                  required
                />
              </div>
              <div className="mb-3">
                <label>Email</label>
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  value={formDataForex.email}
                  onChange={handleChangeForez}
                  required
                />
              </div>
              <div className="mb-3">
                <label>Wallet</label>
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  value={formDataForex.address}
                  onChange={handleChangeForez}
                />
              </div>
              <div className="mb-3">
                <label>Referral</label>
                <input
                  type="text"
                  name="referrerId"
                  className="form-control"
                  value={formDataForex.referrerId}
                  onChange={handleChangeForez}
                />
              </div>
              <div className="mb-3">
                <label>Referral Wallet</label>
                <input
                  type="text"
                  name="refer_wallet"
                  className="form-control"
                  value={formDataForex.refer_wallet}
                  onChange={handleChangeForez}
                />
              </div>
              <div className="mb-3">
                <label>User Type</label>
                <select
                  name="usertype"
                  className="form-select"
                  value={formDataForex?.usertype || ""}
                  onChange={handleChangeForez}
                  required
                >
                  <option value="" disabled>
                    Choose User Type
                  </option>
                  <option value="consultant">AGENT</option>
                  <option value="client">CLIENT</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary" onClick={handleSubmitChnage}>
                SignUp
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserRegester;
